import React from 'react';
import { array, object, string } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '@components/Button/Button.js';
import styles from './Hero.module.scss';
import rectangeOne from '../../../../components/Hero/image/Rectangle1.svg';
import rectangeTwo from '../../../../components/Hero/image/Rectangle2.svg';
import Swiper from 'react-id-swiper';
import Image from '../../../../components/Image/Image';

const Hero = ({ id, primary, items }) => {
  const {
    buttonlink,
    title,
    description,
    buttontext: buttonText,
    previewimage,
  } = primary;

  const params = {
    slidesPerView: 2,
    spaceBetween: 16,
    breakpoints: {
      768: {
        spaceBetween: 32,
      },
      992: {
        allowTouchMove: false,
        slidesPerView: 'auto',
      },
    },
  };

  return (
    <>
      <div className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.title}>
              <RichText render={title.richText} />
            </div>
            <div className={styles.descr}>
              <RichText render={description.richText} />
            </div>
            <div className={styles.buttonWrapper}>
              <Button variant={VARIANT.PRIMARY} to={`${buttonlink.text}`}>
                <RichText render={buttonText.richText} />
              </Button>
            </div>
          </div>
          <div className={styles.imageWrapper}>
            {id === '2ba9f00f-2df1-50dc-af2d-5cccaf6e8f59' && (
              <>
                <img
                  className={styles.afterImage}
                  src={rectangeTwo}
                  alt="rectangeTwo"
                />
                <img
                  className={styles.beforImage}
                  src={rectangeOne}
                  alt="rectangeOne"
                />
              </>
            )}
            <Image className={styles.image} image={previewimage} />
          </div>
        </div>
      </div>
      <div className={styles.partners}>
        <Swiper {...params}>
          {items.map(({ partnerslogo }) => {
            return (
              <>
                <div className={styles.slide}>
                  <Image image={partnerslogo} key={partnerslogo.url} />
                </div>
              </>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

Hero.propTypes = {
  primary: object,
  items: array,
  id: string,
};

export default Hero;
