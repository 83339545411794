import React from 'react';
import { object, array, string } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Image from '@components/Image/Image';
import styles from './Benefit.module.scss';

const Benefit = ({ id, primary, items }) => {
  const { title, description, subtitle, image } = primary;

  return (
    <section className={styles.benefit}>
      <div className={styles.background}>
        <Image image={image} />
      </div>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
        </div>
        <div
          className={
            id !== '8c33f424-a583-584d-a6c4-76e5372b46f1'
              ? styles.description
              : styles.newDescription
          }
        >
          <RichText render={description.richText} />
        </div>
        {id !== '8c33f424-a583-584d-a6c4-76e5372b46f1' && (
          <div className={styles.subtitle}>
            <RichText render={subtitle.richText} />
          </div>
        )}
        <ul className={styles.list}>
          {items.map(({ image, text, description }, index) => {
            const key = index;
            return (
              <li className={styles.item} key={key}>
                <div
                  className={
                    id === '8c33f424-a583-584d-a6c4-76e5372b46f1'
                      ? styles.newImageWrapper
                      : styles.imageWrapper
                  }
                >
                  <Image image={image} />
                </div>
                <div className={styles.subtext}>
                  <RichText render={text.richText} />
                </div>
                <div className={styles.subdescription}>
                  <RichText render={description.richText} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

Benefit.propTypes = {
  primary: object,
  items: array,
  id: string,
};

export default Benefit;
